<template>
  <the-new-header/>
  <div class="page-content competitors-finder-page">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <header-tabs :classes="'desktop-tabs'"
                   v-if="!isMobile">
        <div class="tabs-content">

          <router-link class="tab-item"
                       tag="div"
                       :to="{name: 'CompetitorsFinder'}">
            <span class="text">Competitors Finder</span>
          </router-link>

          <router-link class="tab-item"
                       tag="div"
                       :to="{name: 'MyCompetitors'}">
            <span class="text">Competitors</span>
          </router-link>

          <router-link class="tab-item"
                       tag="div"
                       :to="{name: 'IndirectCompetitors'}">
            <span class="text">Indirect Competitors</span>
          </router-link>

          <router-link class="tab-item"
                       tag="div"
                       :to="{name: 'NonCompetitors'}">
            <span class="text">Non-Competitors</span>
          </router-link>

          <router-link class="tab-item"
                       tag="div"
                       :to="{name: 'AppsTimeline'}">
            <span class="text">Update Timeline</span>
          </router-link>

        </div>
      </header-tabs>

      <header-tabs :classes="'tablet-tabs'"
                   v-if="!isMobile"
                   @dropdown-closed="tabDropdownOpened = false">
        <div class="mobile-tabs-content"
             :class="{active: tabDropdownOpened}">
          <div class="mobile-tabs-heading">
            <div class="active-tab"
                 @click="tabDropdownOpened = !tabDropdownOpened">
              <span class="text">{{ route?.meta?.pageTitle }}</span>
            </div>
            <div class="tab-icon"
                 @click="tabDropdownOpened = !tabDropdownOpened">
              <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
            </div>
          </div>
          <div class="mobile-tabs-list">
            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'CompetitorsFinder'}"
                         @click="tabDropdownOpened = !tabDropdownOpened">
              <span class="text">Competitors Finder</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'MyCompetitors'}"
                         @click="tabDropdownOpened = !tabDropdownOpened">
              <span class="text">Competitors</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'IndirectCompetitors'}"
                         @click="tabDropdownOpened = !tabDropdownOpened">
              <span class="text">Indirect Competitors</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'NonCompetitors'}"
                         @click="tabDropdownOpened = !tabDropdownOpened">
              <span class="text">Non-Competitors</span>
            </router-link>
          </div>
        </div>
      </header-tabs>

      <!--      <the-header>-->
      <!--        <template v-if="!isMobile"-->
      <!--                  v-slot:header-left>-->
      <!--       -->
      <!--        </template>-->
      <!--      </the-header>-->

      <!--      <div class="current-mobile-app mobile-visible"-->
      <!--           v-if="isMobile">-->
      <!--        <transition name="fade">-->
      <!--          <current-app v-if="currentApp?.id"-->
      <!--                       :accordion-initial-height="'auto'"-->
      <!--                       :app-data="currentApp"></current-app>-->
      <!--        </transition>-->
      <!--      </div>-->

      <div class="new-container">
        <template v-if="isMobile">
          <header-tabs @dropdown-closed="tabDropdownOpened = false">
            <div class="mobile-tabs-content"
                 :class="{active: tabDropdownOpened}">
              <div class="mobile-tabs-heading">
                <div class="active-tab"
                     @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">{{ route?.meta?.pageTitle }}</span>
                </div>
                <div class="tab-icon"
                     @click="tabDropdownOpened = !tabDropdownOpened">
                  <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
                </div>
              </div>
              <div class="mobile-tabs-list">
                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'CompetitorsFinder'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">Competitors Finder</span>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'Competitors'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">Competitors</span>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'IndirectCompetitors'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">Indirect Competitors</span>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'NonCompetitors'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">Non-Competitors</span>
                </router-link>
              </div>
            </div>
          </header-tabs>
        </template>

      </div>

      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <div class="new-container">
            <component :is="slotProps.Component" :key="currentApp.id"></component>
          </div>
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router';
import {useStore} from "vuex";
import {ref, onMounted, getCurrentInstance, computed, watch} from 'vue';
import {getCookie, hasBetaCookie} from "@/utils/utils";

export default {
  name: 'CompetitorsFinderPage',
  methods: {hasBetaCookie},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {proxy} = getCurrentInstance();
    let currentAppId = getCookie('currentAppId');
    const apiUrls = proxy?.$ApiUrls;
    const store = useStore();

    let title = ref('');
    let tabDropdownOpened = ref(false);
    let currentKeywordCountryCode = ref('US');
    let currentComponent = ref('overview');
    let topDataIsLoaded = ref(false);

    const isMobile = computed(() => store.getters.isMobile);
    const sidebarIsCollapsed = computed(() => store.getters.sidebarIsCollapsed);
    const currentApp = computed(() => store.getters.currentApp);
    const currentCountryManual = computed(() => store.getters.currentCountryManual);
    const currentCountry = computed(() => store.getters.currentCountry);

    currentKeywordCountryCode.value = currentCountry.value?.code;
    let url = '?country_code=' + currentKeywordCountryCode.value + '&app_id=' + currentAppId;

    function tabClickHandler(name) {
      currentComponent.value = name;
    }

    watch([currentCountry, currentApp, currentCountryManual], async function (newValues, oldValues) {
      if (JSON.stringify(newValues[0]) !== JSON.stringify(oldValues[0])) {
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + currentAppId;
      }

      // if (JSON.stringify(newValues[0]) === JSON.stringify(oldValues[0]) && currentAppId !== newValues[1]?.id && newValues[1]?.id !== oldValues[1]?.id) {
      if (newValues[1]?.id !== oldValues[1]?.id && oldValues[1]?.id) {
        currentAppId = newValues[1]?.id;
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + newValues[1]?.id;
        currentKeywordCountryCode.value = newValues[0]?.code;
      }

      if (JSON.stringify(newValues[2]) !== JSON.stringify(oldValues[2])) {
        url = '?country_code=' + newValues[2]?.code + '&app_id=' + currentAppId;
        currentKeywordCountryCode.value = newValues[0]?.code;
      }
    });

    onMounted(async function () {

    });

    return {
      title,
      isMobile,
      sidebarIsCollapsed,
      currentComponent,
      currentKeywordCountryCode,
      currentApp,
      // tabClickHandler,
      // topAppData,
      // topDataIsLoaded,
      tabDropdownOpened,
      route,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
